import React, {FunctionComponent} from "react"
import "./bankInfo.css"


type props={
    icon?:any
    title:string
    data1:string
    data2:string
    data3:string
}


export const BankInfo: FunctionComponent<props> = ({icon, title, data1, data2, data3}) => {
    return (
        <div className={"businessInfo"}>
            <div className={"bInfo__icon"}>
                {icon}
            </div>
            <div className={"bInfo__data"}>
                <h3>{title}</h3>
                <p>{data1}</p>
                <p>{data2}</p>
                <p>{data3}</p>
            </div>
        </div>

    )
}
