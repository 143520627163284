import React, {FunctionComponent} from "react"
import "./businessInfoStyle.css"

type props={
    icon?:any
    title:string
    data:string
}

export const BusinessInfo: FunctionComponent<props> = ({icon, title, data}) => {
    return (
        <div className={"businessInfo"}>
            <div className={"bInfo__icon"}>
                {icon}
            </div>
            <div className={"bInfo__data"}>
                <h3>{title}</h3>
                <p>{data}</p>
            </div>
        </div>
    )

}
